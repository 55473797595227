export const MINUTE = 60;
export const HOUR = 3600;
export const DAY = 86400;
export const SYNC_TIME = MINUTE * 5;
export const ContactNumber = '+1 (916) 518-1010';
export const CalendlyLink = 'https://calendly.com/the-relief-consultants/erc-intro-call';
export const EDDOnceHub = 'https://go.oncehub.com/TRCTeamPage';
export const SupportEmail = 'support@thereliefconsultants.com';
export const TextLine = '+1 (916) 518-1010';
export const BlogLink = 'https://medium.com/@the-relief-consultants';
export const LinkedInLink = 'https://www.linkedin.com/company/the-relief-consultants/';
export const OtherNumber = '+1 (858) 367-7049';
export const RPInbox = '+1 (858) 869-0932';

export const SE_STATUS = {
    Unreachable: 'Unreachable',
    Ineligible: 'Ineligible',
    Not_Interested: 'Not Interested',
    Awaiting_Docs: 'Awaiting Docs',
    Credit_in_Progress: 'Credit in Progress',
    Awaiting_Client_Signature: 'Awaiting Client Signature',
    Submitted_to_IRS: 'Submitted to IRS',
    IRS_Delay: 'IRS Delay',
    Collecting_Commission: 'Commissionable',
    Deal_Paid: 'Deal Paid',
};

export const STATUS_TO_READABLE = {
    Unreachable: 'Unreachable',
    Ineligible: 'Ineligible',
    'Not Interested': 'Not Interested',
    'Awaiting Docs': 'Awaiting Docs',
    'Credit in Progress': 'Credit in Progress',
    'Awaiting Client Signature': 'Awaiting Client Signature',
    'Submitted to IRS': 'Submitted to IRS',
    'IRS Delay': 'IRS Delay',
    'Collecting Commission': 'Commissionable',
    'Deal Paid': 'Deal Paid',
};

export const LEAD_STATUS_TO_READABLE = {
    Called: 'Called',
    Submitted: 'Awaiting Call',
    'Not Qualified': 'Not Qualified',
    Converted: 'Converted',
    'No Answer': 'No Answer',
    Invalid: 'Invalid',
    'Not Interested': 'Not Interested',
};

export const STORAGE_KEY = {
    RESYNC_VIDEO: 'resync-videos',
    NEW_COURSES: 'new-courses',
};

export const TYPE_OF_LEAD = {
    'self-employment': 'SETC',
    ERC: 'ERC',
};
