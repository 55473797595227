// UI
import { Box, Container, Tab, Tabs } from '@mui/material';
// Styles
import useStyles from './styles';
import Applications from './Applications/Applications';
import Leads from './Leads/Leads';
import ReferralStats from './ReferralStats/ReferralStats';
import { useState } from 'react';
import TabPanel from 'components/Widgets/TabPanel/TabPanel';
import Card from 'components/Card/Card';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

export default function ReferralTracker() {
    const classes = useStyles();
    const location = useLocation();
    const query = queryString.parse(location.search);
    const [tab, setTab] = useState(query.tab === 'Leads' ? 1 : 0);

    return (
        <Box className={classes.root}>
            <Container className={classes.container} maxWidth="lg">
                <ReferralStats />

                <Card cardOnly sx={{ mb: 2 }}>
                    <Tabs value={tab} onChange={(_, v) => setTab(v)}>
                        <Tab label="Applications in Progress" />
                        <Tab label="Leads" />
                    </Tabs>
                </Card>

                <TabPanel value={tab} index={0}>
                    <Applications />
                </TabPanel>
                <TabPanel value={tab} index={1}>
                    <Leads />
                </TabPanel>
            </Container>
        </Box>
    );
}
